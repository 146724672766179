<template>
  <div id="app">
    <el-container v-if="isLogin">
      <el-header><Header /></el-header>
        <el-main>
          <router-view />
        </el-main>
    </el-container>
    <el-container v-else>
      <el-header><Header /></el-header>
        <el-main>
          <el-scrollbar wrap-class="scrollbar-wrapper" style="width:100%; height:100%;box-sizing: border-box;"
                      class="noX">
          <router-view />
          <el-footer><Footer /></el-footer>
          </el-scrollbar>
        </el-main> <!--:style="{ minHeight: minH + 'px', overflow:'hidden' }"-->
    </el-container>
  </div>
</template>
<script>
// import './assets/less/common.less'
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      minH: 0,
      isLogin:false
    };
  },
  watch: {
    $route: {
      handler(val) {
        console.log(val, 'val');
        this.isLogin = val.name == 'Login'
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // var dz = window.location.href;
    //
    // if (!localStorage.getItem("LbjtAccessToken")) {
    //   if (!this.isWeixinBrowser()) {
    //     sessionStorage.setItem("wxLogin", "");
    //   } else {
    //     //微信未登录存第一次页面
    //     if (!dz.includes("ogin")) {
    //       if (dz.includes("www.china6588.com")) {
    //         localStorage.setItem(
    //           "setLastPath",
    //           dz.split("www.china6588.com")[1]
    //         );
    //       } else {
    //         localStorage.setItem(
    //           "setLastPath",
    //           dz.split("http://172.16.30.8:8080")[1]
    //         );
    //       }
    //     }
    //
    //     console.log("setLastPath", localStorage.getItem("setLastPath"));
    //     sessionStorage.setItem("wxLogin", "wechat");
    //     let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxff43c2b546c5196a&redirect_uri=http://www.china6588.com/wxLogin&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
    //
    //     if (!dz.includes("code")) window.location.href = url;
    //   }
    // }
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state.storageId,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
    this.minH = document.documentElement.clientHeight - 320;
    let that = this;
    window.onresize = function() {
      that.minH = document.documentElement.clientHeight - 320;
    };
  },
  methods: {
    // 判断是否为微信浏览器
    isWeixinBrowser() {
      let ua = navigator.userAgent.toLowerCase();
      return /micromessenger/.test(ua) ? true : false;
    }
  }
};
</script>

<style lang="less">
.el-header {
  height: auto !important;
}
.el-main {
  padding: 0;
  min-height: 14rem;
  background-color: #f4f4f4;
}
.el-footer {
  padding: 0;
  background-color: #1c2630;
  height: auto !important;
}
@media screen and (max-width: 900px) {
  .el-header {
    padding: 0;
  }
}
.nox{
  background-color: #f4f4f4;
}
.noX ::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
